import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import useCartStore from "../api/cart";
import { PopupWidget, PopupButton } from "react-calendly";

const KnowMore = () => {
  const { cartItems, updateCartItem } = useCartStore();

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);

  const toggleBox = () => {
    setIsOpen(!isOpen);
    setIsOpen1(!isOpen1);
  };

  const [selectedOptionPrice, setSelectedOptionPrice] = useState(() => {
    const savedOption = localStorage.getItem("optionprice");
    return savedOption || "";
  });

  const [repairLocation, setrepairLocation] = useState("");
  const [repairDescription, setrepairDescription] = useState("");

  const location = useLocation();
  const selectedType = location.state?.selectedType || "";

  const [selectedPlan] = useState(() => {
    const savedPlan = localStorage.getItem("selectedPlan");
    return savedPlan || "";
  });

  const [selectedTask] = useState(() => {
    const savedTask = localStorage.getItem("selectedTask");
    return savedTask || "";
  });

  const [selectedOption] = useState(() => {
    const savedOption = localStorage.getItem("selectedOption");
    return savedOption || "";
  });

  const [notes, setNotes] = useState();

  const navigate = useNavigate();

  let userData = JSON.parse(localStorage.getItem("userData"));

  const [errorInfo, setErrorInfo] = useState(null);
  const handleApply = () => {
    if (repairLocation.length == 0 && localStorage.getItem("task") == "Repairing") {
      setErrorInfo("Please enter details in the repair location box.");
    } else {
      if (localStorage.getItem("task") === "Repairing") {
        const updatedServices = cartItems[0].services.map((service, index) => {
          if (service.name === localStorage.getItem("option") && JSON.parse(localStorage.getItem('selectedItems')).id == service?.item_details?.id) {
            return {
              ...service,
              repair_details: {
                location: repairLocation,
                description: repairDescription,
              },
            };
          } else {
            return service;
          }
        });
        updateCartItem(cartItems[0].id, {
          services: updatedServices,
        });
      } else {
        const updatedServices = cartItems[0].services.map((service, index) => {
          if (service.name === localStorage.getItem("option") && JSON.parse(localStorage.getItem('selectedItems')).id == service?.item_details?.id) {
            return {
              ...service,
              item_notes: notes,
            };
          } else {
            return service;
          }
        });
        updateCartItem(cartItems[0].id, {
          services: updatedServices,
        });
      }
      navigate("/item-detail", { state: { data: location?.state?.data } });
    }
  };

  return (
    <section className=" items-section same-bg-section relative">
      <div className="container padding-x-all">
        <div className="know-more-box sec-com-padding flex-box justify-between align-center">
          <div
            className={`show-box ${!isOpen ? "open mobile-none" : "mobile-none"
              }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {localStorage.getItem("selectedItem")}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {localStorage.getItem("SelectedPlan")}
              </h4>
            </div>

            <div className="show-box-last-list">
              <p className="show-box-para">Task Detail</p>
              <h4 className="show-box-answer">
                {localStorage.getItem('task')}
              </h4>
              <div className="service-job-detail-box">
                <p className="show-box-para">
                  {localStorage.getItem('option')}
                </p>
                {selectedOptionPrice != "" && (
                  <div className="text-box">
                    <h4 className="show-box-answer">${selectedOptionPrice}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`show-box ${!isOpen1 ? "open desktop-none" : "desktop-none"
              }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {localStorage.getItem("selectedItem")}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {localStorage.getItem("SelectedPlan")}
              </h4>
            </div>

            <div className="show-box-last-list">
              <p className="show-box-para">Task Detail</p>
              <h4 className="show-box-answer">
                {localStorage.getItem('task')}
              </h4>
              <p className="show-box-para">
                {localStorage.getItem('option')}
              </p>
              {selectedOptionPrice != "" && (
                <div className="text-box">
                  <h4 className="show-box-answer">${selectedOptionPrice}</h4>
                </div>
              )}
            </div>
          </div>
          {localStorage.getItem('task') !== "Repairing" && (
            <div className="item-select-box">
              <h2>
                Is there anything else you would
                <br /> like for us to know?
              </h2>
              <div class="item-describe-box">
                <h5 class="describe-heading">Notes to our tailors</h5>
                <textarea
                  class="know-more-description"
                  placeholder="e.g. There’s a hole in the knee but I don’t want it fixed or be careful of the lace trim."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  maxLength={300}
                ></textarea>
              </div>
              {errorInfo == null && (
                <p
                  className="para"
                  style={{ fontWeight: "bold", fontSize: "0.8rem" }}
                >
                  {errorInfo}
                </p>
              )}
              <a class="popup-text">
                <PopupButton
                  url="https://calendly.com/fxry/video-call-with-a-fxry-fixer"
                  rootElement={document.getElementById("root")}
                  text="Unsure? Book a free virtual fixer consultation"
                />
              </a>
              <button className="section-main-btn mt-2-8" onClick={handleApply}>
                Next
                <span>
                  <img src={btnArrow} alt="" />
                </span>
              </button>
            </div>
          )}

          {localStorage.getItem('task') === "Repairing" && (
            <div className="item-select-box">
              <h2>Can you add more information about your repair?</h2>
              <p className="para mb-1">A few details to help our fixers.</p>
              <div class="item-describe-box">
                <h5 class="describe-heading">Repair Location</h5>
                <textarea
                  placeholder="eg. on the right elbow or along the seam"
                  maxLength={300}
                  value={repairLocation}
                  onChange={(e) => setrepairLocation(e.target.value)}
                ></textarea>
              </div>
              <div class="item-describe-box">
                <h5 class="describe-heading">Repair Description (Optional)</h5>
                <textarea
                  placeholder="eg. moth holes and wear and tear on sleeve"
                  value={repairDescription}
                  onChange={(e) => setrepairDescription(e.target.value)}
                  maxLength={300}
                ></textarea>
              </div>
              <a class="popup-text">
                <PopupButton
                  url="https://calendly.com/fxry/video-call-with-a-fxry-fixer"
                  rootElement={document.getElementById("root")}
                  text="Unsure? Book a free virtual fixer consultation."
                />
              </a>
              <button
                type="button"
                repairLocation
                className={`mt-2-8 ${repairLocation ? "section-main-btn" : "section-main-btn1"
                  }`}
                onClick={handleApply}
                disabled={repairLocation ? false : true}
              >
                Next
                <span>
                  <img src={btnArrow} alt="" />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default KnowMore;