import create from "zustand";
import { persist } from "zustand/middleware";

// Function to generate a unique ID
const generateUniqueId = () => {
  // Generate a random alphanumeric string as the ID
  return Math.random().toString(36).substring(7);
};

const useCartStore = create(
  persist(
    // Use the persist middleware
    (set, get) => ({
      cartItems: [], // Array to store cart items
      updateProductDetails: (newDetails) => {
        set((state) => ({
          ...state,
          ...newDetails,
        }));
      },
      // Add an item to the cart
      addToCart: (item) => {
        set((state) => ({
          cartItems: [
            ...state.cartItems,
            {
              id: generateUniqueId(), // Generate a unique ID for the item
              ...item,
            },
          ],
        }));
      },
      // Remove an item from the cart based on its ID
      removeFromCart: (itemId) => {
        set((state) => ({
          cartItems: state.cartItems.filter((item) => item.id !== itemId),
        }));
      },
      // Update an item in the cart based on its ID
      updateCartItem: (itemId, updatedItem) => {
        set((state) => ({
          cartItems: state.cartItems.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                ...updatedItem,
              };
            }
            return item;
          }),
        }));
      },
    }),
    {
      name: "product-store", // Name to identify the persisted store
      getStorage: () => localStorage, // Storage mechanism (e.g., localStorage)
    }
  )
);


export default useCartStore;