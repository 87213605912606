import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useCartStore from "../api/cart";

function generateUniqueId() {
  const randomId = Math.floor(Math.random() * 1e10).toString();
  return randomId.padStart(10, '0');
}

function TypeSelectionScreen() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleBox = () => {
    setIsOpen(!isOpen);
  };
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleTypeSelectionData = (item) => {
    // Check if the item is already selected
    if (selectedItem && selectedItem?.name === item?.name) {
      // If it's already selected, deselect it
      setSelectedItem(null);
      setSelectedItems([]);
    } else {
      // If it's not selected, select it
      setSelectedItem(item);
      setSelectedItems([item]); // Update selectedItems array with only the new item
      localStorage.setItem("selectedItem", item?.name);
      localStorage.setItem("selectedItems", JSON.stringify(item));
    }
  };

  const { cartItems, updateCartItem } = useCartStore();
  const location = useLocation();
  const [alerMsg, setAlertMsg] = useState(false);

  useEffect(() => {
    setDescribe("");
    if (
      cartItems &&
      cartItems?.length > 0 &&
      location?.state?.addNewItem === false
    ) {
      const lastSelectedItem =
        cartItems[0]?.itemsData[cartItems[0]?.itemsData?.length - 1];
      setSelectedItems(cartItems[0]?.itemsData);
      setSelectedItem(lastSelectedItem);
    }
    const item1 = localStorage.getItem("selectedItems");
    setSelectedItem(JSON.parse(item1));
    setSelectedItems([JSON.parse(item1)]);
    if (!localStorage.getItem("addnewitem")) {
      if (
        cartItems[0]?.itemsData?.length >= 1 &&
        cartItems[0]?.services?.length >= 1
      ) {
        navigate("/item-detail");
      }
    }
  }, []);
  const pincodeData = location.state?.pincode || "";
  const [selectedType, setSelectedType] = useState(() => {
    const savedType = localStorage.getItem("selectedType");
    return selectedItems.map((itm) => itm) || "";
  });
  const [describe, setDescribe] = useState(() => {
    const savedDescribe =
      cartItems && cartItems[0]?.itemsData?.map((item) => item?.description);
    return (savedDescribe && savedDescribe[0]) || "";
  });
  const [selectedPlan] = useState(() => {
    const savedPlan = localStorage.getItem("selectedPlan");
    return savedPlan || "";
  });
  const [selectedTask] = useState(() => {
    const savedTask = localStorage.getItem("selectedTask");
    return savedTask || "";
  });
  const [selectedOption] = useState(() => {
    const savedOption = localStorage.getItem("selectedOption");
    return savedOption || "";
  });

  const handleApply = () => {
    if (describe == "") {
      setAlertMsg(true);
    }
    if (location?.state?.addNewItem) {
      const uniqueid = generateUniqueId();
      const currentItems = cartItems[0].itemsData || [];
      const combinedItems = [
        ...currentItems,
        ...selectedItems.map((item) => ({
          ...item,
          description: describe,
          id : uniqueid
        })),
      ];
      updateCartItem(cartItems[0].id, { itemsData: combinedItems });
      localStorage.setItem('selectedItems', JSON.stringify({ ...selectedItem, description : describe, id : uniqueid }))
      navigate("/plan", { state: { selectedItem: { ...selectedItem, describe }, pincodeData, id : uniqueid } });
    } else {
      const uniqueid = generateUniqueId();
      updateCartItem(cartItems[0].id, {
        itemsData: [
          ...selectedItems.map((item) => ({
            ...item,
            description: describe,
            id : uniqueid
          })),
        ],
      });
      localStorage.setItem('selectedItems', JSON.stringify({ ...selectedItem, description : describe, id : uniqueid }))
      navigate("/plan", { state: { selectedItem: { ...selectedItem, describe }, pincodeData, id : uniqueid } });
    }
  };

  const fetchAllItems = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}item/getallitem`
    );
    setData(data);
  };

  useEffect(() => {
    fetchAllItems();
  }, []);
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <section className="items-section same-bg-section relative">
      <div className="container padding-x-all">
        <div className="items-box item-pad-box sec-com-padding flex-box justify-between align-center">
          <div
            className={`show-box ${
              isOpen ? "open mobile-none" : "mobile-none"
            }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {location?.state?.addNewItem
                  ? localStorage.getItem("selectedItem") || "-"
                  : selectedItem?.name || "-"}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {selectedPlan ? selectedPlan : "-"}
              </h4>
            </div>
          </div>

          <div
            className={`show-box ${
              isOpen ? "desktop-none" : "open desktop-none"
            }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {location?.state?.addNewItem
                  ? localStorage.getItem("selectedItem") || "-"
                  : selectedItem?.name || "-"}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {selectedPlan ? selectedPlan : "-"}
              </h4>
            </div>
          </div>

          <div className="item-select-box">
            <h2>
              Which item would you like to <br />
              alter or repair?
            </h2>
            <p className="para">
              Choose one item. You can add another item later on.
            </p>

            <div className="wears-box">
              <ul className="items-wears-listing">
                {!isLoading &&
                  data.map((item, index) => (
                    <>
                      <li key={index}>
                        <button
                          onClick={() => handleTypeSelectionData(item)}
                          className={
                            selectedItems &&
                            selectedItems.some(
                              (selected) => selected?.name === item?.name
                            )
                              ? "item-selected"
                              : ""
                          }
                        >
                          {item?.name}
                        </button>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
            <div className="item-describe-box decription-line-fixed">
              <h5 className="describe-heading adjust-sub-head">
                Describe your item
              </h5>
              <p className="para">
                Let us know the brand and color to help our fixers identify your
                item.
              </p>
              <textarea
                value={describe}
                maxLength={300}
                onChange={(e) => setDescribe(e.target.value)}
                placeholder="e.g. tan & yellow striped shirt"
              />
              {alerMsg && (
                <p className="para">
                  Please add a description of your item for our fixers.
                </p>
              )}
            </div>
            <button
              className={`${
                describe == "" ? "section-main-btn1" : "section-main-btn"
              }`}
              type="button"
              disabled={
                describe != "" && selectedItem?.length == undefined
                  ? false
                  : true
              }
              onClick={handleApply}
            >
              Select a service{" "}
              <span>
                <img src={btnArrow} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TypeSelectionScreen;
