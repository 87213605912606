import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PincodeScreen from "./components/PincodeScreen";
import TypeSelectionScreen from "./components/TypeSelectionScreen";
import PlanScreen from "./components/PlanScreen";
import Alteration from "./components/Alteration";
import PerfectFit from "./components/PerfectFit";
import KnowMore from "./components/KnowMore";
import ItemDetail from "./components/ItemDetail";
import Checkout from "./components/Checkout";
import Thankyou from "./components/Thankyou";
import { Toaster } from "react-hot-toast";
import UnderConstruction from "./components/UnderConstruction";

function App() {
  useEffect(() => {
    // Push current state to history to initialize it
    window.history.pushState(null, "", window.location.href);

    // Function to handle back/forward navigation
    const handlePopState = (event) => {
      window.history.pushState(null, "", window.location.href);
    };

    // Add event listener to handle 'popstate' event
    window.addEventListener("popstate", handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  return (
    <Router>
      <Routes>
        {/* <Route path="/*" element={<UnderConstruction />} /> */}

        <Route path="/" element={<PincodeScreen />} />
        <Route path="/types" element={<TypeSelectionScreen />} />
        <Route path="/plan" element={<PlanScreen />} />
        <Route path="/alteration" element={<Alteration />} />
        <Route path="/perfect-fit" element={<PerfectFit />} />
        <Route path="/know-more" element={<KnowMore />} />
        <Route path="/item-detail" element={<ItemDetail />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/thankyou" element={<Thankyou />} />
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
