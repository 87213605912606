import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import pinIcon from "../assets/img/pin.webp";
import matchIcon from "../assets/img/match.webp";
import measureIcon from "../assets/img/measure.webp";
import matchVideo from "../assets/img/matchitems.mov";
import measureVideo from "../assets/img/measuringclothes.mov";
import videoThumnail from "../assets/img/thumbnail.webp";
import playIcon from "../assets/img/play-icon.png";
import tickMark from "../assets/img/check-mark.webp";
import VideoPopup from "./VideoPopup";
import useCartStore from "../api/cart";
import { PopupWidget, PopupButton } from "react-calendly";
import toast from "react-hot-toast";
function isYouTubeURL(url) {
  const pattern =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[A-Za-z0-9_-]{11}$/;
  return pattern.test(url);
}
const PerfectFit = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);

  const toggleBox = () => {
    setIsOpen(!isOpen);
    setIsOpen1(!isOpen1);
  };
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(() => {
    const savedOption = localStorage.getItem("optionprice");
    return savedOption || "";
  });

  const { cartItems, updateCartItem } = useCartStore();

  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = localStorage.getItem("selectedTab");
    return storedTab ? parseInt(storedTab) : 3;
  });

  const location = useLocation();
  const selectedType = location.state?.selectedType || "";

  const [selectedPlan] = useState(() => {
    const savedPlan = localStorage.getItem("selectedPlan");
    return savedPlan || "";
  });

  const [selectedTask] = useState(() => {
    const savedTask = localStorage.getItem("selectedTask");
    return savedTask || "";
  });

  const [selectedOption] = useState(() => {
    const savedOption = localStorage.getItem("selectedOption");
    return savedOption || "";
  });

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [measurement, setmeasurement] = useState();
  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleEnded = () => {
    const video = videoRef.current;
    video.currentTime = 0;
    video.play();
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleVideoClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const navigate = useNavigate();

  const selectTab = (tabNumber) => {
    setSelectedTab(tabNumber);
    setmeasurement();
  };
  const [checked, setIsChecked] = useState(false);
  const [checked1, setIsChecked1] = useState(false);
  const getUserData = JSON.parse(localStorage.getItem("userData"));

  const [errorInfo, setErrorInfo] = useState(null);

  const handleApply = () => {
    if (selectedTab == 1 && checked == true) {
      const updatedServices = cartItems[0].services.map((service, index) => {
        const selectedItemsData1 = JSON.parse(localStorage.getItem("selectedItems"))
        if (service.name === localStorage.getItem("option") && selectedItemsData1?.id == service?.item_details?.id) {
          return {
            ...service,
            alterationDetails: {
              type:
                selectedTab == 1
                  ? "Pin Items"
                  : selectedTab == 2
                    ? "Match Items"
                    : "Measure",
              measurement: measurement,
            },
          };
        } else {
          return service;
        }
      });
      if (selectedTab && updatedServices) {
        updateCartItem(cartItems[0].id, {
          services: updatedServices,
        });
      }
      navigate("/know-more", {
        state: {
          selectedOption: location.state.selectedOption,
          selectedTask: location.state.selectedTask,
          data: location.state.data,
        },
      });
    } else if (selectedTab == 2 && measurement != undefined && measurement.length != 0 && checked1 == true) {
      const updatedServices = cartItems[0].services.map((service, index) => {
        let selectedItemsData2 = JSON.parse(localStorage.getItem("selectedItems"))
        if (service.name === localStorage.getItem("option") && selectedItemsData2?.id == service?.item_details?.id) {
          return {
            ...service,
            alterationDetails: {
              type:
                selectedTab == 1
                  ? "Pin Items"
                  : selectedTab == 2
                    ? "Match Items"
                    : "Measure",
              measurement: measurement,
            },
          };
        } else {
          return service;
        }
      });
      if (selectedTab && updatedServices) {
        updateCartItem(cartItems[0].id, {
          services: updatedServices,
        });
      }
      navigate("/know-more", {
        state: {
          selectedOption: location.state.selectedOption,
          selectedTask: location.state.selectedTask,
          data: location.state.data,
        },
      });
    } else if (selectedTab == 3 && measurement != undefined && measurement.length != 0) {
      const updatedServices = cartItems[0].services.map((service, index) => {
        let selectedItemsData3 = JSON.parse(localStorage.getItem("selectedItems"))
        if (service.name === localStorage.getItem("option") && selectedItemsData3?.id == service?.item_details?.id) {
          return {
            ...service,
            alterationDetails: {
              type:
                selectedTab == 1
                  ? "Pin Items"
                  : selectedTab == 2
                    ? "Match Items"
                    : "Measure",
              measurement: measurement,
            },
          };
        } else {
          return service;
        }
      });
      if (selectedTab && updatedServices) {
        updateCartItem(cartItems[0].id, {
          services: updatedServices,
        });
      }
      navigate("/know-more", {
        state: {
          selectedOption: location.state.selectedOption,
          selectedTask: location.state.selectedTask,
          data: location.state.data,
        },
      });
    } else {
      if (selectedTab == 1) {
        setErrorInfo("Please check the input box!");
      } else if (selectTab == 2) {
        setErrorInfo("Please enter the description details && check the box!");
      } else {
        setErrorInfo("Please enter the description details");
      }
    }
  };

  const [videoUrl, setVideoUrl] = useState(localStorage.getItem("pinVideo"));
  let videoOne = localStorage.getItem("pinVideo");
  const [infoModes, setInfoModes] = useState(null);

  useEffect(() => {
    cartItems[0]?.services?.map((service, index) => {
      if (service?.name == location?.state?.selectedOption || service?.name == localStorage.getItem("option")) {
        setInfoModes(service?.info_mode);
        if (service?.info_mode?.length == 0) {
          navigate("/know-more", {
            state: {
              selectedOption: location.state.selectedOption || localStorage.getItem("option"),
              selectedTask: location.state.selectedTask || localStorage.getItem("task"),
              data: location.state.data,
            },
          });
        }
        setVideoUrl(service?.pin_item_video);
      }
    });
  }, [location.state]);

  return (
    <section className="items-section same-bg-section relative">
      <div className="container padding-x-all">
        <div className="items-box sec-com-padding flex-box justify-between align-center">
          <div
            className={`show-box ${!isOpen ? "open mobile-none" : "mobile-none"
              }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {location?.state?.data?.selectedItem
                  ? location?.state?.data?.selectedItem?.name
                  : localStorage.getItem("selectedItem")}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {location?.state?.data?.addNewService
                  ? location?.state?.data?.selectedItem?.pricing_plan
                  : localStorage.getItem("SelectedPlan")}
              </h4>
            </div>

            <div className="show-box-last-list">
              <p className="show-box-para">Task Detail</p>
              <h4 className="show-box-answer">
                {localStorage.getItem("task")}
              </h4>
              <div className="service-job-detail-box">
                <p className="show-box-para">
                  {localStorage.getItem("option")}
                </p>
                {selectedOptionPrice != "" && (
                  <div className="text-box">
                    <h4 className="show-box-answer">${selectedOptionPrice}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`show-box ${!isOpen1 ? "open desktop-none" : "desktop-none"
              }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {location?.state?.data?.selectedItem
                  ? location?.state?.data?.selectedItem?.name
                  : localStorage.getItem("selectedItem")}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {location?.state?.data?.addNewService
                  ? location?.state?.data?.selectedItem?.pricing_plan
                  : localStorage.getItem("SelectedPlan")}
              </h4>
            </div>

            <div className="show-box-last-list">
              <p className="show-box-para">Task Detail</p>
              <h4 className="show-box-answer">
                {localStorage.getItem("task")}
              </h4>

              <div className="service-job-detail-box">
                <p className="show-box-para">
                  {localStorage.getItem("option")}
                </p>
                {selectedOptionPrice != "" && (
                  <div className="text-box">
                    <h4 className="show-box-answer">${selectedOptionPrice}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="item-select-box">
            <h2>Help us to get the perfect fit.</h2>
            <p className="para">Select an option.</p>

            <div className="perfect-fit-box flex-box justify-between">
              <div className="tabs-box">
                {infoModes?.includes("Measure Items") && (
                  <button
                    onClick={() => selectTab(3)}
                    className={selectedTab === 3 ? "item-selected" : ""}
                  >
                    <img src={measureIcon} alt="" />
                    <h6>Measure</h6>
                  </button>
                )}
                {infoModes?.includes("Pin Items") && (
                  <button
                    onClick={() => selectTab(1)}
                    className={selectedTab === 1 ? "item-selected" : ""}
                  >
                    <img src={pinIcon} alt="" />
                    <h6>Pin Items</h6>
                  </button>
                )}
                {infoModes?.includes("Match Items") && (
                  <button
                    onClick={() => selectTab(2)}
                    className={selectedTab === 2 ? "item-selected" : ""}
                  >
                    <img src={matchIcon} alt="" />
                    <h6>Match Items</h6>
                  </button>
                )}
              </div>
              <div className="tab-content-box">
                {selectedTab === 1 && (
                  <div className="common-box content-box-one flex-box justify-between">
                    <div className="video-box relative">
                      <h4 className="desktop-none">Pinning 101:</h4>
                      {localStorage.getItem("pinVideo") != "null" &&
                        <iframe
                          src={`https://www.youtube.com/embed/${localStorage.getItem("pinVideo").match(/shorts\/([a-zA-Z0-9_-]+)/)[1]}`}
                          title="Matching Items"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      }

                      {localStorage.getItem("pinVideo") == "null" &&
                        <>
                          {showPopup && (
                            <VideoPopup
                              videoSrc={videoOne}
                              onClose={handleClosePopup}
                            />
                          )}
                          <video
                            ref={videoRef}
                            src={videoOne}
                            autoPlay
                            muted
                            poster={videoThumnail}
                            onClick={handleVideoClick}
                            onEnded={handleEnded}
                            onPlay={handlePlay}
                            onPause={handlePause}
                            className="desktop-none"
                          ></video>
                          <div
                            className="play-btn desktop-none"
                            onClick={togglePlay}
                            style={{
                              display: isPlaying ? "none" : "block",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={playIcon} alt="Play" />
                          </div>
                          <video
                            type="video/quicktime"
                            className="mobile-none"
                            ref={videoRef}
                            src={videoOne}
                            autoPlay
                            muted
                            poster={videoThumnail}
                            onClick={togglePlay}
                            onEnded={handleEnded}
                            onPlay={handlePlay}
                            onPause={handlePause}
                          ></video>
                          <div
                            className="play-btn mobile-none"
                            onClick={togglePlay}
                            style={{
                              display: isPlaying ? "none" : "block",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={playIcon} alt="Play" />
                          </div>
                        </>
                      }
                    </div>
                    <div className="text-box">
                      <h4 className="mobile-none">Pinning 101:</h4>
                      <p className="para">
                        For simple adjustments, you can pin the item yourself or
                        with a friend's help. Watch our tutorial video for a
                        step-by-step guide.{" "}
                      </p>
                      <a class="popup-text">
                        <PopupButton
                          url="https://calendly.com/fxry/video-call-with-a-fxry-fixer"
                          rootElement={document.getElementById("root")}
                          text="Unsure? Book a free virtual fixer consultation"
                        />
                      </a>
                      <h5>
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={(e) => setIsChecked(!checked)}
                        />
                        I confirm that I pinned the item.{" "}
                      </h5>
                    </div>
                    {errorInfo != null &&
                      errorInfo == "Please check the input box!" && (
                        <p className="para">{errorInfo}</p>
                      )}
                  </div>
                )}
                {selectedTab === 2 && (
                  <div className="common-box content-box-two flex-box justify-between">
                    <div className="video-box relative">
                      <h4 className="desktop-none">Match Tutorial</h4>
                      <iframe
                        src={"https://www.youtube.com/embed/b2R_uUL8kcI"}
                        title="Matching Items"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="text-box">
                      <h4 className="mobile-none">Match Tutorial</h4>
                      <p className="para">
                        Send in your favorite item that fits the way you want
                        the altered item to fit. Ensure they are similar (e.g.,
                        both high-waisted jeans).{" "}
                      </p>
                      <a class="popup-text">
                        <PopupButton
                          url="https://calendly.com/fxry/video-call-with-a-fxry-fixer"
                          rootElement={document.getElementById("root")}
                          text="Unsure? Book a free virtual fixer consultation"
                        />
                      </a>
                      <div className="item-describe-box">
                        <h5 className="describe-heading">
                          Match Item Description
                        </h5>
                        <textarea
                          className="match-description"
                          placeholder="e.g. blue & white button down striped dress"
                          onChange={(e) => setmeasurement(e.target.value)}
                        />
                        <h5>
                          <input
                            type="checkbox"
                            checked={checked1}
                            onChange={(e) => setIsChecked1(!checked1)}
                          />
                          I confirm that I matched the item.{" "}
                        </h5>
                        {errorInfo != null &&
                          errorInfo != "Please check the input box!" && (
                            <p className="para">{errorInfo}</p>
                          )}
                      </div>
                    </div>
                  </div>
                )}
                {selectedTab === 3 && (
                  <div className="common-box content-box-top flex-box justify-between">
                    <div className="video-box relative">
                      <h4 className="desktop-none">Match Tutorial</h4>
                      <iframe
                        src={"https://www.youtube.com/embed/ZCePDBN-aMs"}
                        title="Matching Items"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="text-box">
                      <h4>Adjustment Notes</h4>
                      <p className="para">
                        Please tell us how much you would like to change your
                        item by and specify inches.
                        <br />
                        Our fixers would love to know your own relevant
                        measurements too.
                      </p>
                      <a class="popup-text">
                        <PopupButton
                          url="https://calendly.com/fxry/video-call-with-a-fxry-fixer"
                          rootElement={document.getElementById("root")}
                          text="Unsure? Book a free virtual fixer consultation"
                        />
                      </a>
                      <div className="item-describe-box">
                        <h5 className="describe-heading">
                          Measure Description
                        </h5>
                        <textarea
                          className="match-description"
                          // placeholder='e.g.Take 2" off the hem. My Inseam is 27.'
                          placeholder="Example notes  “Sure, for the skirt, the measurements are as follows: - Waist: 28 inches - Hips: 36 inches - Length: 22 inches"
                          onChange={(e) => setmeasurement(e.target.value)}
                        />
                      </div>
                      {errorInfo != null &&
                        errorInfo != "Please check the input box!" && (
                          <p className="para">{errorInfo}</p>
                        )}
                      <div className="example-note-box">
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <button
              className={`${(selectedTab == 3 && measurement) ||
                (selectedTab == 1 && checked == true) ||
                (selectedTab == 2 &&
                  measurement != undefined &&
                  measurement.length != 0 &&
                  checked1 == true)
                ? "section-main-btn"
                : "section-main-btn1"
                }`}
              type="button"
              disabled={
                (selectedTab == 3 && measurement) ||
                  (selectedTab == 1 && checked == true) ||
                  (selectedTab == 2 &&
                    measurement != undefined &&
                    measurement.length != 0 &&
                    checked1 == true)
                  ? false
                  : true
              }
              onClick={handleApply}
            >
              Next
              <span>
                <img src={btnArrow} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerfectFit;