import React, { useState, useEffect, useRef } from "react";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import iconOne from "../assets/img/pincode-icon-1.webp";
import iconTwo from "../assets/img/pincode-icon-2.webp";
import iconThree from "../assets/img/fxry-promise-icon-3.webp";
import add from "../assets/img/add.png";
import deleteIcon from "../assets/img/delete.webp";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useCartStore from "../api/cart";

const pathURL = process.env.REACT_APP_API_URL;

const fetchCode = async ({ queryKey }) => {
  const id = queryKey[1];
  const { data } = await axios.get(`${pathURL}service/getservicebyitem/${id}`);
  return data;
};

const ItemDetail = () => {
  const { cartItems, updateCartItem, removeFromCart } = useCartStore();
  const [total, setTotal] = useState("00.00");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const location = useLocation();
  const selectedType = location.state?.selectedType || "";

  const navigate = useNavigate();

  const handleApply = () => {
    navigate("/checkout");
  };

  const addNew = () => {
    localStorage.setItem("addnewitem", true);
    navigate("/types", {
      state: { addNewItem: true },
    });
  };

  const addService = (data) => {
    console.log(data,'aman')
    localStorage.setItem("addnewservice", true);
    localStorage.setItem("SelectedPlan", data?.pricing_plan);
    localStorage.setItem("selectedItem", data?.name);
    localStorage.setItem("selectedItems", JSON.stringify(data));
    navigate("/alteration", {
      state: { addNewService: true, selectedItem: data, addNewItem: false },
    });
  };

  const [subtotalAmount, setsubtotalAmount] = useState(0);
  useEffect(() => {
    const filteredData = {
      itemsData: cartItems[0].itemsData.filter((item) =>
        cartItems[0].services.some((service) => item.id === service.item_details.id && item.name.trim() === service.item_details.name.trim())
      ),
      services: cartItems[0].services.filter((service) => cartItems[0].itemsData.some((item) => item.id === service.item_details.id && item.name.trim() === service.item_details.name.trim())),
    };
    updateCartItem(cartItems[0]?.id, {
      itemsData: filteredData.itemsData,
      services: filteredData.services,
    });
    cartItems[0]?.services?.reduce((acc, item) => {
      const pricingPlan = cartItems[0]?.itemsData?.filter((items) => items?.id == item?.item_details?.id);
      let updatedPrice;
      if (pricingPlan != undefined) {
        updatedPrice =
          pricingPlan[0]?.pricing_plan === "Standard"
            ? item?.standard_price
            : item?.premium_price;
        const mainprice = acc + parseFloat(updatedPrice);
        setsubtotalAmount(mainprice);
        return acc + parseFloat(updatedPrice);
      }
    }, 0);
    localStorage.removeItem("SelectedPlan");
    localStorage.removeItem("selectedItem");
    localStorage.removeItem("selectedItems");
    localStorage.removeItem("addnewitem");
    localStorage.removeItem("addnewservice");
    localStorage.removeItem("task");
    localStorage.removeItem("option");
    localStorage.removeItem("optionprice");
    if (cartItems && cartItems?.length == 0) {
      localStorage.removeItem("product-store");
    }
    if (
      cartItems[0]?.itemsData?.length == 0 &&
      cartItems[0]?.services?.length == 0
    ) {
      localStorage.removeItem("product-store");
    }
  }, []);

  const handleRemove = (serviceArr, itemIndex) => {
    const filteredItems = cartItems[0]?.itemsData?.filter((item, i) => i != itemIndex);
    const filteredServices = cartItems[0]?.services?.filter((service, i) => !serviceArr.includes(i));

    updateCartItem(cartItems[0].id, {
      itemsData: filteredItems,
      services: filteredServices,
    });
    if (filteredServices && filteredServices.length == 0) {
      updateCartItem(cartItems[0]?.id, {
        services: [],
        itemsData: [],
      });
      setsubtotalAmount(0);
      navigate("/item-detail");
    }
    filteredServices.reduce((acc, item) => {
      const pricingPlan = cartItems[0]?.itemsData?.filter((items) => items?.id == item?.item_details?.id);
      let updatedPrice;
      if (pricingPlan != undefined) {
        updatedPrice = pricingPlan[0]?.pricing_plan === "Standard" ? item.standard_price : item.premium_price;
        const mainprice = acc + parseFloat(updatedPrice);
        setsubtotalAmount(mainprice);
        return acc + parseFloat(updatedPrice);
      }
    }, 0);
  };

  function toCamelCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return (
    <section className="item-detail-section items-section same-bg-section relative">
      <div className="container padding-x-all ">
        <div className="item-detail-box sec-com-padding flex-box justify-between align-center">
          <div
            className="show-detail-box scroll-wrapper"
            style={{ maxHeight: "33rem", overflowY: "scroll" }}
          >
            <button className="add-new-item-box relative" onClick={addNew}>
              <h4 className="add-new-text">Add a new items</h4>
            </button>
            {cartItems[0]?.itemsData?.map((item, index) => {
              return (
                <>
                  <div
                    className="price-detail-box"
                    style={{ marginTop: "1rem" }}
                  >
                    <div className="item-name-price flex-box justify-between relative">
                      <h3
                        className="name-price-text item-name-text"
                        key={index}
                      >
                        {item?.name.trim() || "-"}{" "}
                        <span>
                          <img
                            src={deleteIcon}
                            onClick={() => {
                              let indexData = cartItems[0]?.services
                                ?.map((service, index) => {
                                  if (service?.item_details?.id == item?.id) {
                                    return index;
                                  }
                                })
                                .filter((service) => service != undefined);
                              handleRemove(indexData, index);
                            }}
                            alt=""
                          />
                        </span>{" "}
                      </h3>
                      <h3 className="name-price-text">
                        {item?.itemsData?.type} {item?.pricing_plan}
                      </h3>
                      <p className="para">Order Details</p>
                      {cartItems[0]?.services?.map((service) => {
                        if (service?.item_details?.id == item?.id) {
                          return (
                            <>
                              <table className="item-table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <p>Item Description:</p>
                                    </td>
                                    <td>
                                      <p>
                                        {item?.describe || item?.description}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p>Service Type:</p>
                                    </td>
                                    <td>
                                      <p>{service?.selectedService}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p>Service:</p>
                                    </td>
                                    <td>
                                      <p>{service.name.trim()}</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p>Price:</p>
                                    </td>
                                    <td>
                                      <p>
                                        {item?.pricing_plan === "Standard"
                                          ? "$ " + service?.standard_price
                                          : "$ " + service?.premium_price}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          );
                        }
                      })}
                    </div>
                    <div className="add-another-service-box">
                      <button onClick={() => addService(item)}>
                        <h5>
                          {" "}
                          <span>
                            <img src={add} alt="" />
                          </span>{" "}
                          Add another service to your {item?.name.trim()}
                        </h5>
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="sub-total-box">
            <ul className="sub-total scroll-wrapper">
              {cartItems[0]?.services?.map((carts, index) => {
                let pricingPlan = cartItems[0]?.itemsData?.find((itm) => { return carts?.item_details?.id == itm?.id });
                return (
                  <>
                    <li>
                      <h4>{toCamelCase(carts?.name)}</h4>
                      <h4>
                        $
                        {pricingPlan?.pricing_plan === "Standard"
                          ? carts.standard_price
                          : carts.premium_price}
                      </h4>
                    </li>
                  </>
                );
              })}
              <li>
                <h4>Pickup & Drop-off fee</h4>
                <h4>$5.00</h4>
              </li>
              <li>
                <h4>Tax - </h4>
                <h4>Calculated at next step</h4>
              </li>
              <li>
                <h4>Your basket</h4>
                <h4>${subtotalAmount + 5}</h4>
              </li>
            </ul>
            <div className="frxy-promise">
              <h4 className="fxry-promise-text">The FXRY Promise</h4>
              <ul className="fxry-usp-listing pincode-usp-listing flex-box">
                <li>
                  <div className="img-box">
                    <img src={iconOne} alt="" />
                  </div>
                  <div className="text-box">
                    <p>
                      <strong>Two-Week </strong> <br />
                      Turnaround
                    </p>
                  </div>
                </li>
                <li>
                  <div className="img-box">
                    <img src={iconTwo} alt="" />
                  </div>
                  <div className="text-box">
                    <p>
                      <strong> Quality</strong> <br />
                      Craftsmanship
                    </p>
                  </div>
                </li>
                <li>
                  <div className="img-box">
                    <img src={iconThree} alt="" />
                  </div>
                  <div className="text-box">
                    <p>
                      <strong>Customer </strong> <br />
                      Convenience
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <button
              className="checkout-btn section-main-btn"
              onClick={handleApply}
              disabled={cartItems[0]?.itemsData?.length == 0 ? true : false}
            >
              Continue to checkout
              <span>
                <img src={btnArrow} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemDetail;